import request from './request'

const sendEvent = () => {}
const logger = {
  log: (s) => console.log(s)
}
const track = () => {}

/*
<Impression><![CDATA[https://xs2.instreamatic.com/stat/impression.gif?r=167074990&v=e2CFaa7cEd27b25B]]></Impression>
<Impression><![CDATA[https://rs.mail.ru/pixel/AJzsAcGyPzVY9xNLTGbCmCwT7FlLHV1M3CRDLpMU_FUBYi7Ka6onp9SgRARKBCvTk1gOb27W2Wp547CnbkYzuxbPug-TUbmeDinD0nJbP_14RA9yrqlu_La1FAtboQWWrEUubJLRQXelE0AXnT21dwB_yY9yTLKBkKw1CTXlSR0MwsLf.gif]]></Impression>
*/
function getImpressions(xml) {
  let impressions = xml.querySelectorAll('Impression')

  return Array.from(impressions).map(item => {
    return {
      value: item.textContent.trim()
    }
  })
}

/*
<TrackingEvents>
    <Tracking event="creativeView"><![CDATA[https://xs2.instreamatic.com/stat/creativeView.gif?r=798784652&v=e2CFaa7cEd27b25B]]></Tracking>
    <Tracking event="start"><![CDATA[https://xs2.instreamatic.com/stat/start.gif?r=90225726&v=e2CFaa7cEd27b25B]]></Tracking>
    <Tracking event="firstQuartile"><![CDATA[https://xs2.instreamatic.com/stat/firstQuartile.gif?r=49993885&v=e2CFaa7cEd27b25B]]></Tracking>
    <Tracking event="midpoint"><![CDATA[https://xs2.instreamatic.com/stat/midpoint.gif?r=368321322&v=e2CFaa7cEd27b25B]]></Tracking>
    <Tracking event="thirdQuartile"><![CDATA[https://xs2.instreamatic.com/stat/thirdQuartile.gif?r=997718026&v=e2CFaa7cEd27b25B&x=EfaBEB31a14DAC79]]></Tracking>
    <Tracking event="complete"><![CDATA[https://xs2.instreamatic.com/stat/complete.gif?r=147234545&v=e2CFaa7cEd27b25B]]></Tracking>
</TrackingEvents>
*/
function getTrackingEvents(xml) {
  let trackingEvents = xml.querySelectorAll('TrackingEvents Tracking')

  return Array.from(trackingEvents).map(item => {
    return {
      event: item.getAttribute('event'),
      value: item.textContent.trim()
    }
  })
}

/*
<MediaFiles>
    <MediaFile id="mp3_0" delivery="progressive" type="audio/mp3" bitrate="0" codec="ACC" maintainAspectRatio="false" scalable="true" width="1" height="1"><![CDATA[https://r.mradx.net/img/25/512E6C.mp3]]></MediaFile>
    <MediaFile id="m4a_0" delivery="progressive" type="video/mp4" bitrate="0" codec="ACC" maintainAspectRatio="false" scalable="true" width="640" height="360"><![CDATA[https://r.mradx.net/img/2F/57D57B.mp4]]></MediaFile>
</MediaFiles>
*/
function getAudioFiles(xml) {
  let audioFiles = xml.querySelectorAll('MediaFile[type="audio/mp3"], MediaFile[type="audio/mpeg"]')
  
  return Array.from(audioFiles).map(item => {
    return {
      value: item.textContent.trim() 
    }
  })
}

function getVideoFiles(xml) {
  let audioFiles = xml.querySelectorAll('MediaFile[type="video/mp4"]')
  
  return Array.from(audioFiles).map(item => {
    return {
      value: item.textContent.trim() 
    }
  })
}

function getScripts(xml) {
  let audioFiles = xml.querySelectorAll('MediaFile[type="application/javascript"]')
  return Array.from(audioFiles).map(item => {
    return {
      value: item.textContent.trim()
    }
  })
}

function getAdParams(xml) {
  let items = xml.querySelectorAll('AdParameters')

  return Array.from(items).map(item => {
    return {
      value: item.textContent.trim()
    }
  })
}

/*
Юнисаунд

<CompanionAds>
  <Companion width="128" height="128">
      <StaticResource creativeType="image/png"><![CDATA[https://r.mradx.net/img/62/1A2F85.png]]></StaticResource>
      <CompanionClickTracking><![CDATA[https://xs2.instreamatic.com/stat/click.gif?r=391137685&v=e2CFaa7cEd27b25B]]></CompanionClickTracking>
      <CompanionClickTracking><![CDATA[https://rs.mail.ru/pixel/AJzsAS5oc4BFNCHEaGtTrMVD1s9zbAzm_Y-TOoJaIUJpse4twgEu4jKF1k-Wmqm7RrMivYwIllWptImuf4zS7eXkPFCdkWf1zn2610EcZVrsNHfQuI3iHnKttIIrwR14uwkwP7V1TfzosobA1ThC9dEBZ5Lt40MfK7I78M4R1hgBU80m.gif]]></CompanionClickTracking>
      <CompanionClickThrough><![CDATA[https://r.mail.ru/redir/AJzsAWJt_B0eK0OgAY5MD3heQwz23p_a9ITm1fj8ZoRvzYFrKqYBrJULZBeyuB8noMj1-FFfhx8h2edHP6uYnk-7BLsxFxZ382-_9Xk3MTIe6ziZc3QiCFpOoY-yj1wTqlb1m11VMYHomyOGTbMJ2Ki02RJtpbK3YRqxlsaUIM7fnJMh]]></CompanionClickThrough>
  </Companion>
</CompanionAds>

Валидным считаем любой Companion у которого есть тэг StaticResource. Остальные теги необязательны

Яндекс

<Icons>
  <Icon program="AdImage" height="900" width="900" xPosition="0" yPosition="0">
    <StaticResource>
      <![CDATA[
      https://avatars.mds.yandex.net/get-canvas/1637513/2a0000016d19604beee93ea8f5a9c064bfe4/optimize
      ]]>
    </StaticResource>
  </Icon>
</Icons>
*/
function getBanners(xml) {
  let banners = xml.querySelectorAll('CompanionAds Companion, Icons Icon')

  return Array.from(banners).map(item => {
    let tags = Array.from(item.childNodes)
    let staticResource = tags.find(tag => tag.tagName == 'StaticResource')
    let clickThrough = tags.find(tag => tag.tagName == 'CompanionClickThrough')
    let tracking = tags.filter(tag => tag.tagName == 'CompanionClickTracking').map(item => {
      return {
        value: item.textContent.trim()
      }
    })

    return {
      height: item.getAttribute('height'),
      link: clickThrough ? clickThrough.textContent.trim() : null,
      tracking: tracking,
      value: staticResource ? staticResource.textContent.trim() : null,
      width: item.getAttribute('width')
    }
  }).filter(item => item.value)
}

function getDuration(xml) {
  let duration = xml.querySelector('Duration')
  return duration ? duration.textContent.trim() : null
}

function getText(xml) {
  let text = xml.querySelector('Extension[type="linkTxt"]')
  return text ? text.textContent.trim() : null
}

function getDomTree(plainXml) {
  let xml = null
  
  if (window.DOMParser) {
    xml = (new DOMParser()).parseFromString(plainXml, "text/xml")
  } else {
    xml = new ActiveXObject("Microsoft.XMLDOM")
    xml.async = false
    xml.loadXML(plainXml)
  }

  return xml
}

function getAdLink(xml) {
  let link = xml.querySelectorAll('ClickThrough')
  return Array.from(link).map(item => {
    return {
      value: item.textContent ? item.textContent : ''
    }
  })
}

function mergeVast(vast1, vast2) {
  if(vast2.scripts && vast2.scripts.length > 0) {
    vast2.scripts[0].value
  }

  return {
    impressions: vast1.impressions.concat(vast2.impressions),
    trackingEvents: vast1.trackingEvents.concat(vast2.trackingEvents),
    audioFiles: vast1.audioFiles.concat(vast2.audioFiles),
    videoFiles: vast1.videoFiles.concat(vast2.videoFiles),
    scripts: vast1.scripts.concat(vast2.scripts),
    adParams: vast1.adParams.concat(vast2.adParams),
    banners: vast1.banners.concat(vast2.banners),
    duration: vast1.duration || vast2.duration,
    text: vast1.text || vast2.text
  }
}

export async function getVastData(link, networkName) {
  //http://127.0.0.1:8990/uploads/vast.xml
  sendEvent('Реклама', 'Запрос рекламы', networkName)

  let response = await request(link)

  if (!window) return false

  let vast = {}

  let xml = getDomTree(response.response)

  // рекламная сеть не нашла подходящий рекламный ролик
  if (xml.getElementsByTagName('nobanner').length) { 
    logger.log(`${networkName} вернула код nobanner`)
    return false
  }

  vast.impressions = getImpressions(xml)
  vast.trackingEvents = getTrackingEvents(xml)
  vast.audioFiles = getAudioFiles(xml)
  vast.videoFiles = getVideoFiles(xml)
  vast.scripts = getScripts(xml)
  vast.adParams = getAdParams(xml)
  vast.banners = getBanners(xml)
  vast.duration = getDuration(xml)
  vast.text = getText(xml)
  vast.videoLink = getAdLink(xml)
  // пришел редирект на другую рекламную сеть

  if (xml.getElementsByTagName('VASTAdTagURI').length) {
    logger.log(`${networkName} вернула редирект на другую рекламную сеть`)
    let vast2 = await getVastData(xml.getElementsByTagName('VASTAdTagURI')[0].textContent.trim())
    if (vast2) vast = mergeVast(vast, vast2)
  }
  console.log('getVastData func мерж двух рекламных сетей 1', vast)
  return vast
}

let emptyPromise = () => {
  return new Promise((resolve, reject) => {
    window.rmgVASTblock = false
    resolve()
  })
}

export async function trackAdvertising(audio, data, networkName, trackingType, adsType) {
  if (data.trackingEvents && data.trackingEvents.length) {
    let creativeView = data.trackingEvents.filter(event => event.event == 'creativeView')
    let start = data.trackingEvents.filter(event => event.event == 'start')
    let firstQuartile = data.trackingEvents.filter(event => event.event == 'firstQuartile')
    let midpoint = data.trackingEvents.filter(event => event.event == 'midpoint')
    let thirdQuartile = data.trackingEvents.filter(event => event.event == 'thirdQuartile')
    let complete = data.trackingEvents.filter(event => event.event == 'complete')
    let pause = data.trackingEvents.filter(event => event.event == 'pause')
    let resume = data.trackingEvents.filter(event => event.event == 'resume')

    let flags = {
      firstQuartile: true,
      midpoint: true,
      thirdQuartile: true,
      hasBeenPaused: false
    }

    // остановка воспроизведения
    audio.onpause = () => {
      if (pause && pause.length) {
        pause.forEach(event => {
          track(event.value, trackingType, adsType)
          logger.log(`Реклама ${networkName} поставлена на паузу ${event.value}`)
          flags.hasBeenPaused = true
        })
        sendEvent('Реклама', 'Реклама поставлена на паузу', networkName)
      }
    }

    audio.onplay = () => {
      if (flags.hasBeenPaused) {
        // продолжение воспроизведения
        if (resume && resume.length) {
          resume.forEach(event => {
            track(event.value, trackingType, adsType)
            logger.log(`Воспроизведение рекламы ${networkName} возобновлено ${event.value}`)
          })
          sendEvent('Реклама', 'Воспроизведение рекламы возобновлено', networkName)
        }
      } else {
        // старт воспроизведения
        if (data.impressions && data.impressions.length) {
          data.impressions.forEach(impression => {
            track(impression.value, trackingType, adsType)
            logger.log(`Impression ${networkName} ${impression.value}`)
          })
          sendEvent('Реклама', 'Impression', networkName)
        }

        if(creativeView && creativeView.length) {
          creativeView.forEach(event => {
            track(event.value, trackingType, adsType)
            logger.log(`CreativeView ${networkName} ${event.value}`)
          })
          sendEvent('Реклама', 'CreativeView', networkName)
        } 
      
        if(start && start.length) {
          start.forEach(event => {
            track(event.value, trackingType, adsType)
            logger.log(`Начало воспроизведения ${networkName} ${event.value}`)
          })
          sendEvent('Реклама', 'Начало воспроизведения', networkName)
        }
      }
    }

    audio.ontimeupdate = () => {
      let time = Number(audio.duration)
      if(time && audio.currentTime != NaN && audio.currentTime != Infinity){
        const result = new Date(Math.round(time - audio.currentTime) * 1000).toISOString().substr(17, 2)
      }
      // первая четверть воспроизведения
      if (firstQuartile && firstQuartile.length && flags.firstQuartile && audio.currentTime > audio.duration*0.25) {
        firstQuartile.forEach(event => {
          track(event.value, trackingType, adsType)
          logger.log(`Первая четверть воспроизведения ${networkName} ${event.value}`)
          flags.firstQuartile = false
        })
        sendEvent('Реклама', 'Первая четверть воспроизведения', networkName)
      }

      // половина воспроизведения
      if (midpoint && midpoint.length && flags.midpoint && audio.currentTime > audio.duration*0.5) {
        midpoint.forEach(event => {
          track(event.value, trackingType, adsType)
          logger.log(`Середина воспроизведения ${networkName} ${event.value}`)
          flags.midpoint = false
        })
        sendEvent('Реклама', 'Середина воспроизведения', networkName)
      }

      // третья четверть воспроизведения
      if (thirdQuartile && thirdQuartile.length && flags.thirdQuartile && audio.currentTime > audio.duration*0.75) {
        thirdQuartile.forEach(event => {
          track(event.value, trackingType, adsType)
          logger.log(`Третья четверть воспроизведения ${networkName} ${event.value}`)
          flags.thirdQuartile = false
        })
        sendEvent('Реклама', 'Третья четверть воспроизведения', networkName)
      }
    }

    // конец воспроизведения рекламы
    return new Promise((resolve, reject) => {
      audio.addEventListener('ended', () => {
        if (complete && complete.length) {
          complete.forEach(event => {
            logger.log(`Конец воспроизведения ${networkName} ${event.value}`)
          })
          sendEvent('Реклама', 'Конец воспроизведения', networkName)
        }

        audio.pause()

        logger.log(`Воспроизведение рекламы ${networkName} завершено`)
        window.rmgVASTblock = false
        resolve()
      })
    })
  } else {
    return emptyPromise()
  }
}

export async function playAudio(data, networkName, trackingType, adsType) {
  if ('rmgVASTblock' in window && window.rmgVASTblock) { 
    logger.log(`Реклама уже запущена`)
    return
  } else {
    logger.log(`Реклама еще не запущена`)
  }

  if (!data.audioFiles || data.audioFiles.length == 0) { 
    logger.log(`Не найдены аудиофайлы рекламы ${networkName}`)
    sendEvent('Реклама', 'Не найдены аудиофайлы рекламы', networkName)
    return emptyPromise()
  }

  window.rmgVASTblock = true
  
  let audio = new Audio()

  audio.src = data.audioFiles[0].value
  let promise = trackAdvertising(audio, data, networkName, trackingType, adsType)

  try {
    await new Promise((resolve, reject) => {
      audio.volume = 1

      audio.play().then(() => {
        resolve()
      }).catch(error => {
        logger.log(`Не удалось загрузить аудиофайл рекламы ${networkName}`)
        throw new Error()
      })
    })
  } catch (error) {
    return emptyPromise()
  }

  return { audio, promise }
}

async function playVideo(data,  networkName, trackingType, videoElem) {
  if ('rmgVASTblock' in window && window.rmgVASTblock) {
    logger.log(`Реклама уже запущена`)
    return
  } else {
    logger.log(`Реклама еще не запущена`)
  }

  if (!data.videoFiles || data.videoFiles.length == 0) {
    logger.log(`Не найдены видеофайлы рекламы ${networkName}`)
    sendEvent('Реклама', 'Не найдены видеофайлы рекламы', networkName)
    return emptyPromise()
  }

  window.rmgVASTblock = true
  let video = videoElem
  video.src = data.videoFiles[0]

  let promise = trackAdvertising(video, data, networkName, trackingType)


  
  return promise
}

export function findBanner(advBanners, size = 48, form = 'square') {
  if (advBanners.length == 0) return null

  let banners = []

  if (form == 'square') {
    banners = advBanners.filter(i => parseInt(i.width) === parseInt(i.height))
  } else if (form == 'horizontal') {
    banners = advBanners.filter(i => parseInt(i.width) > parseInt(i.height) )
  } else if (form == 'vertical') {
    banners = advBanners.filter(i => parseInt(i.width) < parseInt(i.height))
  }

  let biggerBanner = null
  let nearestBanner = null

  for (let i = 0; i < banners.length; i++) {
    if (biggerBanner) {
      if (banners[i].width >= size && (banners[i].width - size < biggerBanner.width - size)) biggerBanner = banners[i]
    } else {
      biggerBanner = banners[i]
    }

    if (nearestBanner) {
      if (Math.abs(banners[i].width - size) < Math.abs(nearestBanner.width - size)) nearestBanner = banners[i]
    } else {
      nearestBanner = banners[i]
    }
  }

  return biggerBanner || nearestBanner
}

export async function playVideoVpaid(data, selector) {
  if ('rmgVASTblock' in window && window.rmgVASTblock) {
    logger.log(`Реклама уже запущена`)
    return
  } else {
    logger.log(`Реклама еще не запущена`)
  }

  if (!data.scripts || data.scripts.length == 0) {
    logger.log(`Не найдены видеофайлы рекламы ${networkName}`)
    sendEvent('Реклама', 'Не найдены видеофайлы рекламы', networkName)
    return emptyPromise()
  }

  window.rmgVASTblock = true

  let promise = getVPAIDVideo(data, selector)
  return promise
}

function getVPAIDVideo(vast, selector, networkName) {
  return new Promise( (resolve, reject) => {
    let vpaidScript = vast.scripts[0].value
    const script = document.createElement('script')
    script.src = vpaidScript
    let videoWrap = document.querySelector(selector)
    videoWrap.appendChild(script)

    let vpaidObj = null
    let environmentVars = {}
    script.onload = async () => {
      if(window.getVPAIDAd){
        vpaidObj = window.getVPAIDAd()
        environmentVars.slot = document.querySelector('#readloud-vpaid-slot')
        environmentVars.videoSlot = document.querySelector('#readloud-vpaid-video-slot')
        environmentVars.videoSlotCanAutoPlay = true

        console.log('Объект getVPAIDAd найден', vpaidObj)

        let ua = global.navigator.userAgent || 'all'
        let width = 550
        let height = 300
        if (ua && ua != 'all'){
          if(/iPad|iPhone|iPod/.test(ua) || ua.toLowerCase().indexOf("android") > -1){
            width = window.screen.availWidth
            height = window.screen.availHeight
          }
        }

        if(vast.adParams.length != 0 && vast.adParams[0].value) {
          vpaidObj.initAd( width, height, 'normal', 500, {AdParameters:  vast.adParams[0].value}, environmentVars )
          console.log('vpaid initAd with params')
        } else {
          vpaidObj.initAd( width, height, 'normal', 500, environmentVars )
          console.log('vpaid initAd')
        }

        let adLoadedVpaid = () => {
          console.log('vpaid adLoaded')

          vpaidObj.startAd()

          let vpaidTimer = setTimeout( () => {
            console.log('vpaid setTimeout')
            deleteVpaidJsFile(videoWrap)
            clearTimeout(vpaidTimer)
            resolve()
          }, 4000)

          let adEndOrSkip = () => {
            console.log('vpaid skiped or end ')
            logger.log(`Воспроизведение рекламы ${networkName} завершено`)
            window.rmgVASTblock = false

            deleteVpaidJsFile(videoWrap)

            resolve()
          }

          let adStart = () => {
            clearTimeout(vpaidTimer)
            console.log('vpaid AdStarted')
          }
          vpaidObj.subscribe(adStart, 'AdStarted', this)

          if(vpaidObj.getAdLinear()) {
            console.log('vpaid getAdLinear')

            vpaidObj.subscribe(adEndOrSkip, 'AdVideoComplete', this)
            vpaidObj.subscribe(adEndOrSkip, 'AdSkipped', this)
          } else {
            console.log('vpaid adLoaded fail')
            deleteVpaidJsFile(videoWrap)
            window.rmgVASTblock = false
            resolve()
          }
        }

        let adErrorVpaid = (e) => {
          console.log(e)
          console.log('Ошибка воспроизведения видео рекламы формата vpaid')
          window.rmgVASTblock = false
          resolve()
        }

        vpaidObj.subscribe(adErrorVpaid, 'AdError', this)
        vpaidObj.subscribe(adLoadedVpaid, 'AdLoaded', this)
      } else {
        console.log('Объект getVPAIDAd не найден')
        window.rmgVASTblock = false
        resolve()
      }
    }

    script.onerror = () => {
      console.log('Скрипт vpaid не загрузился')
      resolve()
    }
  })
}

function deleteVpaidJsFile(videoWrap) {
  console.log('Удаление vpaid')  
  if(videoWrap) {
    videoWrap = videoWrap.childNodes
    videoWrap = Array.prototype.slice.call(videoWrap)

    videoWrap = videoWrap.filter(item => item.tagName == 'SCRIPT')
    videoWrap.forEach(item => item.remove())
  }

  let anotherScriptVpaid = Array.prototype.slice.call(document.body.childNodes)
  anotherScriptVpaid = anotherScriptVpaid.filter(item => item.tagName == 'SCRIPT' && item.src == 'https://cdn.videonow.ru/v2/589f8e8/vn_providers_vpaid.js')
  anotherScriptVpaid.forEach(item => item.remove())
}