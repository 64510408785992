export default function request (url, method='GET', payload=null) {
  return new Promise((resolve, reject) => {
    let request = new XMLHttpRequest()
    request.withCredentials = true
    request.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        resolve(request)
      } else if (this.readyState == 4 && (this.status == 301 || this.status == 302)) {
        console.log(`Редирект ${url}`)
      } else if (this.readyState == 4) {
        console.log(`Ошибка запроса ${url}`)
        resolve(false)
      }
    }
    request.open(method, url, true)

    if (payload) {
      request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
      request.send(JSON.stringify(payload))
    } else {
      request.setRequestHeader('Content-Type', 'application/xml')
      request.send()
    }
  })
}