'use strict'

import { v4 } from 'uuid'
import { getVastData, playAudio, findBanner, playVideoVpaid, trackAdvertising } from './src/vast.js'
import request from './src/request.js'
import { sendAnalytics } from './src/analytics.js'
import { API_HOST } from './src/const.js'

let adsPlayed = false
let isAdsPlaying = false
let adsMedia = null

window.readloud = async function ({
  playerContainerId,
  textContainerSelector,
  titleContainerSelector,
  createdAt='1970-01-01 00:00:00',
  theme='white',
  siteId
}) {
  const sessionId = v4()

  const localAnalytics = (name) => {
    sendAnalytics(name, sessionId, siteId)
  }

  let text = ''
  let title = ''

  if (playerContainerId[0] === '#') playerContainerId = playerContainerId.slice(1)

  const playerContainer = document.getElementById(playerContainerId)
  if (!playerContainer) {
    localAnalytics('Не найден контейнер плеера')
    throw new Error(`Не найден контейнер плеера ${playerContainerId}`)
  }

  const site = await request('https://api-dev-readloud.apptimizm.pro/api/v1/sites/' + siteId)
  const siteData = JSON.parse(site.responseText)

  if (!siteData.active) return

  const textContainer = Array.from(document.querySelectorAll(textContainerSelector))
  if (textContainer.length === 0) {
    text = textContainerSelector
  } else {
    text = textContainer.map(element => {
      return element.innerText.replace(/\s+/g, ' ')
    }).join(' ')
  }

  const titleContainer = Array.from(document.querySelectorAll(titleContainerSelector))
  if (titleContainer.length === 0) {
    title = titleContainerSelector
  } else {
    title = titleContainer.map(element => {
      return element.innerText.replace(/\s+/g, ' ')
    }).join(' ')
  }

  const audio = new Audio()
  let isStarted = false

  const getAudio = () => {
    return isAdsPlaying ? adsMedia : audio
  }

  const getVoice = async () => {
    await new Promise((resolve, reject) => {
      const request = new XMLHttpRequest()
      request.open('POST', 'https://api-dev-readloud.apptimizm.pro/api/v1/voicer/announce/', true)
      request.setRequestHeader('Content-type', 'application/json;charset=UTF-8')
      request.onreadystatechange = () => {
        if (request.readyState == 4 && request.status == 200) {
          const response = JSON.parse(request.responseText)
          audio.src = response.file
          resolve()
          if (!isAdsPlaying) {
            document.getElementById('readloud-loader-wrapper').style.display = 'none'
            document.getElementById('readloud-play-icon').style.display = 'block'
          }
          return
        }
      }
      request.send(JSON.stringify({ 
        title,
        text,
        created_at: createdAt,
        news_url: window.location.toString()
      }))
    })
  }

  let isUpdateTime = true

  const changeCirclePosition = (event) => {
    isUpdateTime = false
    const inputLine = event.target
    
    inputLine.addEventListener('mousemove', updateCircleLeft)

    inputLine.addEventListener('mouseup', () => {
      isUpdateTime = true
      inputLine.removeEventListener('mousemove', updateCircleLeft, false)
    })
  }

  const updateCircleLeft = (e) => {
    if (isUpdateTime) return

    const input = e.target
    if (e.offsetX < 0 || e.offsetX > input.offsetWidth) return

    const circle = input.previousElementSibling
    circle.style.left = `${(e.offsetX / input.offsetWidth) * 100}%`
  }

  const bindTimeUpdate = (element) => {
    element.addEventListener('timeupdate', () => {
      if (!isUpdateTime) return
  
      const progress = element.currentTime / element.duration * 100
      document.getElementById('readloud-timeline').style.left = `${progress}%` 
      document.getElementById('readloud-timeline-input').value = progress
  
      let minutes = Math.floor(element.currentTime / 60)
      let seconds = element.currentTime - minutes * 60
      minutes = minutes < 10 ? '0' + minutes.toFixed(0) : minutes.toFixed(0)
      seconds = seconds < 10 ? '0' + seconds.toFixed(0) : seconds.toFixed(0)
  
      document.getElementById('readloud-time-counter').innerText = `${minutes}:${seconds}`
    })
  }

  bindTimeUpdate(audio)

  audio.addEventListener('durationchange', () => {
    if (document.getElementById('readloud-loader-wrapper').style.display == 'block') {
      document.getElementById('readloud-loader-wrapper').style.display = 'none'
      document.getElementById('readloud-play-icon').style.display = 'block'
    }
  })

  audio.addEventListener('ended', () => {
    document.getElementById('readloud-play-icon').style.display = 'block'
    document.getElementById('readloud-pause-icon').style.display = 'none'

    localAnalytics('Конец воспроизведения аудио')
  })
  
  const startPlay = async () => {
    isStarted = true

    const event = new Event('onReadloudPlay')
    window.dispatchEvent(event)
    
    try {
      if (!adsPlayed) {
        localAnalytics('Старт рекламы')
        isAdsPlaying = true
        let advLink = await request(API_HOST + '/ads?domen=' + window.location.host)
        advLink = JSON.parse(advLink.response)

        if (advLink.results.length > 0) {
          const advData = await getVastData(advLink.results[0].ad_link)
          const banner = findBanner(advData.banners, window.innerWidth)

          document.getElementById('readloud-advertising').style.display = 'block'
          document.getElementById('readloud-speed-counter').style.display = 'none'

          if (advData.videoFiles.length) {
            document.getElementById('readloud-advertising-banner').style.display = 'none'
            const video = document.getElementById('readloud-advertising-video')

            video.src = advData.videoFiles[0].value
            adsMedia = video

            trackAdvertising(video, advData)

            bindTimeUpdate(video)

            video.play()

            document.getElementById('readloud-play-icon').style.display = 'none'
            document.getElementById('readloud-pause-icon').style.display = 'block'

            await new Promise((resolve) => {
              video.addEventListener('ended', () => {
                document.getElementById('readloud-advertising').style.display = 'none'
                resolve()
              })
            })
          } else if (advData.scripts.length) {
            document.getElementById('readloud-advertising-banner').style.display = 'none'
            document.getElementById('readloud-advertising-video').style.display = 'none'
            document.getElementById('readloud-player').style.display = 'none'

            await playVideoVpaid(advData, '#readloud-vpaid-container')
            
            document.getElementById('readloud-advertising').style.display = 'none'
            document.getElementById('readloud-player').style.display = 'grid'
          } else {
            document.getElementById('readloud-advertising-banner').src = banner?.value
            document.getElementById('readloud-advertising-link').href = banner?.link
            document.getElementById('readloud-advertising-video').style.display = 'none'

            const { audio, promise } = playAudio(advData)

            adsMedia = audio

            bindTimeUpdate(adsMedia)

            document.getElementById('readloud-play-icon').style.display = 'none'
            document.getElementById('readloud-pause-icon').style.display = 'block'

            await promise

            document.getElementById('readloud-advertising').style.display = 'none'
            document.getElementById('readloud-player').style.display = 'grid'
          }
        }

        isAdsPlaying = false
        adsPlayed = true
        localAnalytics('Конец рекламы')
      }
    } catch (e) {
      localAnalytics('Во время воспроизведения рекламы произошла ошибка')
      console.error('Во время воспроизведения рекламы произошла ошибка')
      console.error(e)
    }

    if (!audio.src) {
      localAnalytics('Кнопка воспроизведения нажата до окончания загрузки аудио')
      document.getElementById('readloud-loader-wrapper').style.display = 'block'
      document.getElementById('readloud-play-icon').style.display = 'none'
      return
    }

    document.getElementById('readloud-speed-counter').style.display = 'block'

    localAnalytics('Начало воспроизведения аудио')

    audio.currentTime = 0
    await audio.play()

    localAnalytics('Начало воспроизведения аудио')

    document.getElementById('readloud-play-icon').style.display = 'none'
    document.getElementById('readloud-pause-icon').style.display = 'block'
  }

  const endPlay = async () => {
    getAudio().pause()

    document.getElementById('readloud-play-icon').style.display = 'block'
    document.getElementById('readloud-pause-icon').style.display = 'none'
    
    const event = new Event('onReadloudPause')
    window.dispatchEvent(event)

    localAnalytics('Воспроизведение остановлено')
  }

  const resumePlay = async () => {
    getAudio().play()

    document.getElementById('readloud-play-icon').style.display = 'none'
    document.getElementById('readloud-pause-icon').style.display = 'block'
    
    const event = new Event('onReadloudPlay')
    window.dispatchEvent(event)

    localAnalytics('Воспроизведение возобновлено')
  }

  const toggleStart = () => isStarted ? resumePlay() : startPlay()

  const setPlaybackSpeed = () => {
    audio.playbackRate += 0.5
    if (audio.playbackRate > 3) audio.playbackRate = 0.5
    document.getElementById('readloud-speed-counter').innerText = 'x' + audio.playbackRate
    localAnalytics('Изменена скорость воспроизведения')
  }

  const showVolume = () => {
    document.getElementById('readloud-volume-line').style.display = 'block'
    document.getElementById('readloud-speed-counter').style.display = 'none'
  }

  const hideVolume = () => {
    document.getElementById('readloud-volume-line').style.display = 'none'
    document.getElementById('readloud-speed-counter').style.display = 'block'
  }

  const drawVolume = (v) => {
    if (v == 0) document.getElementById('readloud-volume-icon').className = 'volume-icon icon volume-icon-mute'
    else if (v > 0 && v < 50) document.getElementById('readloud-volume-icon').className = 'volume-icon icon volume-icon-mid'
    else if (v > 50) document.getElementById('readloud-volume-icon').className = 'volume-icon icon'
  }

  const setVolume = (event) => {
    getAudio().volume = event.target.value / 100
    document.getElementById('readloud-volume-slide').style.left = `${event.target.value}%`

    drawVolume (event.target.value)

    localAnalytics('Изменена громкость')
  }

  let prevVolume = 0

  const toggleVolume = () => {
    if (getAudio().volume == 0) {
      getAudio().volume = prevVolume
      drawVolume(prevVolume * 100)

      localAnalytics('Звук включен')
    } else {
      prevVolume = getAudio().volume
      getAudio().volume = 0
      drawVolume(0)

      localAnalytics('Звук выключен')
    }
  }

  const setTime = (event) => {
    audio.currentTime = event.target.value / 100 * audio.duration
    document.getElementById('readloud-timeline').style.left = `${event.target.value}%`

    localAnalytics('Перемотка аудио')
  }

  const sliderLineTemplate = (id, initialValue=0) => `
    <div class="slider-line">
      <div class="circle" style="left: ${initialValue}%" id="${id}"></div>
      <input type="range" min="0" max="100" step="1" id="${id}-input" value="${initialValue}"></input>
    </div>
  `

  const template = `
    <div class="player-wrapper readloud-player readloud-player-${theme}">
      <div class="readloud-player-advertising" id="readloud-advertising" style="display: none;">
        <a id="readloud-advertising-link" href="#" target="_blank">
          <img id="readloud-advertising-banner" src=""/>
          <video id="readloud-advertising-video"></video>
        </a>
        <div id="readloud-vpaid-container">
          <div id="readloud-vpaid-slot"><div id="readloud-vpaid-video-slot"></div></div>
        </div>
      </div>
      <div class="player" id="readloud-player">
        <a class="logo" href="https://readloud.ru/" target="_blank"></a>
        <div class="left">
          <div class="play-or-pause">
            <div id="readloud-loader-wrapper" style="display: none;">
              <div class="readloud-loader-main-loader">
                <div class="readloud-loader-loader">
                  <svg class="readloud-loader-circular-loader" viewBox="25 25 50 50" >
                    <circle class="readloud-loader-loader-path" cx="50" cy="50" r="20" fill="none" stroke-width="4" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="play icon" id="readloud-play-icon"></div>
            <div class="pause icon" id="readloud-pause-icon" style="display: none;"></div>
          </div>
          <div class="time-line">
            ${sliderLineTemplate('readloud-timeline')}
          </div>
          <div class="time-counter" id="readloud-time-counter"></div>
        </div>
        <div class="right">
          <div class="speed-counter" id="readloud-speed-counter">×1</div>
          <div class="volume" id="readloud-volume">
            <div class="volume-line" id="readloud-volume-line" style="display: none;">
              ${sliderLineTemplate('readloud-volume-slide', 100)}
            </div>
            <div class="volume-icon icon" id="readloud-volume-icon"></div>
          </div>
        </div>
      </div>
    </div>
  `

  playerContainer.innerHTML = template
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    document.getElementById('readloud-volume').style.display = 'none'
  }

  document.getElementById('readloud-pause-icon').addEventListener('click', endPlay)
  document.getElementById('readloud-play-icon').addEventListener('click', toggleStart)
  document.getElementById('readloud-speed-counter').addEventListener('click', setPlaybackSpeed)
  document.getElementById('readloud-volume').addEventListener('mouseover', showVolume)
  document.getElementById('readloud-volume').addEventListener('mouseout', hideVolume)
  document.getElementById('readloud-volume-icon').addEventListener('click', toggleVolume)
  document.getElementById('readloud-volume-slide-input').addEventListener('change', setVolume)
  document.getElementById('readloud-timeline-input').addEventListener('change', setTime)
  document.getElementById('readloud-volume-slide-input').addEventListener('mousedown', changeCirclePosition)
  document.getElementById('readloud-timeline-input').addEventListener('mousedown', changeCirclePosition)

  localAnalytics('Старт получения аудио')
  getVoice()
  localAnalytics('Конец получения аудио')
}